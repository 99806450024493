import React from 'react';

import Nav from './nav';

const styles = require('./Footer-styles.module.css');

const footer = () => (
  <footer className={styles.footer}>
    <Nav />
    <p>
      &copy; 2021 Superposition. Published by <a href="https://firstversionist.com">Firstversionist</a>
    </p>
  </footer>
);

export default footer;

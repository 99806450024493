import React, { useState, useRef, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Logo from './Logo-inverted-horizontal.svg';
import Form from './Form';

import init from './Hero-canvas';

const styles = require('./Hero-small-styles.module.css');

export default () => {
  const canvas = useRef(null);

  const [canvasInitialized, setCanvasInitialized] = useState(false);

  useEffect(() => {
    if (!canvasInitialized) {
      init(canvas.current, { maxRadius: 2, vy: -0.25, shootingStarInterval: 200 });
      setCanvasInitialized(true);
    }
  });

  return (
    <StaticQuery
      query={query}
      render={data => (
        <div className={styles.hero}>
          <canvas ref={canvas} className={styles.canvas} />

          <div className={styles.row}>
            <div className={styles.col}>
              <header>
                <a href="/">
                  <h1>
                    <Logo />
                    {data.site.siteMetadata.company} design tokens and design system generator
                  </h1>
                </a>
              </header>
            </div>
            <div className={styles.col} />
          </div>
        </div>
      )}
    />
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        company
        title
        tagline
        description
        author
      }
    }
  }
`;

import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, keywords, title, pathname }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = description || data.site.siteMetadata.description;
        const { ogDescription } = data.site.siteMetadata;

        const socialTitle = title ? `${title} | ${data.site.siteMetadata.ogTitle}` : `${data.site.siteMetadata.title}`;
        const pageTitleTemplate = title ? `%s | ${data.site.siteMetadata.ogTitle}` : `${data.site.siteMetadata.title}`;

        const url = `${data.site.siteMetadata.siteUrl}${pathname || '/'}`;

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title || data.site.siteMetadata.title}
            titleTemplate={pageTitleTemplate}
            meta={[
              {
                name: 'google-site-verification',
                content: '8i3b1pcpCLO7AC7VTQhQAxKhvlAhl3_1G8-Njp8F3wU',
              },
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: socialTitle,
              },
              {
                property: `og:url`,
                content: url,
              },
              {
                property: `og:description`,
                content: ogDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: data.site.siteMetadata.siteUrl + data.file.publicURL,
              },
              {
                property: `og:image:width`,
                content: '1024',
              },
              {
                property: `og:image:height`,
                content: '512',
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`,
              },
              {
                name: `twitter:site`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:text:title`,
                content: socialTitle,
              },
              {
                name: `twitter:title`,
                content: socialTitle,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:image`,
                content: data.site.siteMetadata.siteUrl + data.file.publicURL,
              },
              {
                name: `twitter:image:alt`,
                content: 'Screenshot of Superposition',
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          />
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        company
        tagline
        description
        author
        ogDescription
        ogTitle
        siteUrl
      }
    }
    file: file(relativePath: { eq: "images/social.png" }) {
      publicURL
    }
  }
`;

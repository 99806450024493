import React, { Component } from 'react';
import UAParser from 'ua-parser-js';

import addToMailchimp from 'gatsby-plugin-mailchimp';
import { Alert } from 'react-bytesize-icons';

const getPlatform = () => {
  const result = new UAParser().getResult();
  let platformName = result.os.name;
  let version;

  switch (platformName) {
    case 'Windows':
      version = 'exe';
      break;
    case 'Ubuntu':
      version = 'deb';
      break;
    case 'Linux':
      version = 'appimage';
      break;
    case 'Mac OS':
      version = 'dmg';
      break;
    default:
      version = 'exe';
      platformName = 'Windows';
      break;
  }

  return version;
};

const downloads = [
  {
    version: 'exe',
    name: 'Windows',
    link: 'https://github.com/firstversionist/superposition/releases/download/v1.2.1/Superposition-Setup-1.2.1.exe',
    text: 'Tested with Windows 10, min 4GB ram',
  },
  {
    version: 'dmg',
    name: 'Mac OS',
    link: 'https://github.com/firstversionist/superposition/releases/download/v1.2.1/Superposition-1.2.1.dmg',
    text: 'Tested with Mac OS Mojave, min 4GB ram',
  },
  {
    version: 'deb',
    name: 'Ubuntu (.deb)',
    link: 'https://github.com/firstversionist/superposition/releases/download/v1.2.1/superposition_1.2.1_amd64.deb',
    text: 'Compatible with Ubuntu 18.04+, min 4GB ram',
  },
  {
    version: 'appimage',
    name: 'Linux (.AppImage)',
    link: 'https://github.com/firstversionist/superposition/releases/download/v1.2.1/Superposition-1.2.1.AppImage',
    text: 'Compatible with most Linux OS, min 4GB ram',
  },
];

const styles = require('./Form-styles.module.css');

class Form extends Component {
  constructor() {
    super();

    this.state = {
      downloadVisible: false,
      selectedPlatform: '',
      link: '',
      helptext: 'For Mac, Windows and Linux',
    };
  }

  componentDidMount() {
    const platform = getPlatform();
    const currentPlatform = downloads.filter(item => item.version === platform)[0];

    this.setState({
      selectedPlatform: currentPlatform.version,
      link: currentPlatform.link,
      helptext: currentPlatform.text,
    });
  }

  updateDownload = e => {
    const newVersion = e.target.value;
    const newPlatform = downloads.filter(item => item.version === newVersion)[0];

    this.setState({
      selectedPlatform: newPlatform.version,
      link: newPlatform.link,
      helptext: newPlatform.text,
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const email = e.target.elements[0].value;
    await addToMailchimp(email);

    if (typeof window !== 'undefined' && window.ga) {
      try {
        window.ga('send', 'pageview', `${window.location.pathname}/request-app`);
      } catch (e) {}
    }
    this.setState({ downloadVisible: true });
  };

  resetForm = () => {};

  downloadPlugins = () => {
    if (typeof window !== 'undefined' && window.ga) {
      window.ga('send', 'pageview', `${window.location.pathname}/download-app`);
    }
  };

  render() {
    const { downloadVisible, selectedPlatform, link, helptext } = this.state;

    return (
      <>
        <form className={styles.newsletter} onSubmit={this.handleSubmit}>
          {downloadVisible ? (
            <>
              <select value={selectedPlatform} onChange={this.updateDownload}>
                {downloads.map(item => (
                  <option value={item.version} key={item.version}>
                    {item.name}
                  </option>
                ))}
              </select>
              <a href={link} download onClick={this.downloadPlugins}>
                Download
              </a>
            </>
          ) : (
            <>
              <label style={{ fontSize: 0, position: 'absolute' }} htmlFor="mce-EMAIL">
                Email
              </label>
              <input
                placeholder="Your e-mail"
                type="email"
                defaultValue=""
                name="EMAIL"
                required
                className="required email"
                id="mce-EMAIL"
                onFocus={this.resetForm}
              />
              <button type="submit" value="Subscribe" name="subscribe" id="subscribe" className="button">
                Get app
              </button>
            </>
          )}
        </form>
        <p className={styles.helptext}>
          {downloadVisible ? helptext : 'We send email about updates.'}
          <br />
          {downloadVisible ? (
            <>
              Get our browser plugin:{' '}
              <a href="https://chrome.google.com/webstore/detail/superposition-helper/dkabagejkcmeakglamklmcloggfpbfgi">
                Chrome
              </a>
              , <a href="https://addons.mozilla.org/en-US/firefox/addon/superposition-helper/">Firefox</a>
            </>
          ) : (
            <>&nbsp;</>
          )}
        </p>

        {downloadVisible && selectedPlatform === 'exe' && (
          <p className={styles.additional}>
            <strong>
              <Alert width={16} height={16} color="#f00" strokeWidth="10.125%" /> Be aware,
            </strong>{' '}
            The Superposition installer is "not downloaded often" yet. Some operating systems and antivirus warns you
            for this, but that does not mean Superposition is unsafe.
            <br />
            <br />
            If you get a "Windows Protected your PC" popup, press "More Info" and verify that the publisher says
            "Firstversionist B.V.", then click "Run Anyway".
          </p>
        )}
      </>
    );
  }
}

export default Form;

import React from 'react';


const styles = require('./nav-styles.module.css');

const nav = () => (
  <nav className={styles.nav}>
    <ul>
      <li>
        <a href="/addons/">Addons</a>
      </li>
      <li>
        <a href="/changelog/">Changelog</a>
      </li>
      <li>
        <a href="/support/">Support</a>
      </li>
      <li>
        <a href="https://twitter.com/superpositionDS">Twitter</a>
      </li>
    </ul>
  </nav>
);

export default nav;
